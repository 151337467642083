
export default {
  name: 'PressNewsletter',
  data () {
    return {
      showAllFields: false,
    }
  },
  methods: {
    onShowForm () {
      this.showAllFields = true
    },
  },
}
