
import { metaDataMixin } from '~/mixins'

export default {
  mixins: [
    metaDataMixin,
  ],
  async asyncData ({ $api, store, route }) {
    const { data: { items = [] } = {} } = await $api.persons.getAll({
      domain: 'generieke-content-eo',
      role: 'presentator',
      sort: 'title',
      limit: 200, // only 30 available in july 2021
      offset: 0,
    })

    store.dispatch('breadcrumbs/set', {
      path: route.fullPath,
      floating: true,
      width: 10,
      items: [
        {
          to: '/pers',
          label: 'Pers',
        },
        {
          label: 'Presentatoren',
        },
      ],
    })

    const presenters = items

    return {
      presenters,
      searchResults: presenters,
    }
  },
  data () {
    return {
      hero: 'https://corporate-eo.cdn.eo.nl/{format}/5sxqfjv8kksb-anne-mar-zwart-en-bert-van-leeuwen-c-willemjandebruin-fotografie.jpg',
      keyword: null,
      presenters: null,
      programs: {},
      searchResults: null,
      seo: {
        title: 'Overzicht van presentatoren',
        description: 'Dit zijn alle presentatoren van de EO.',
      },
    }
  },
  watch: {
    keyword () {
      this.search()
    },
  },
  mounted () {
    // Asynchronously get all program names that are connected to the presenters.
    setTimeout(() => {
      this.presenters.forEach((p) => this.setProgramsForPresenter(p))
    }, 600)
  },
  methods: {
    search () {
      /**
       * Search for presenters with the given text string by the user
       * @returns {Promise<void>}
       */
      if (!this.keyword) {
        this.searchResults = this.presenters
        return
      }
      const keyword = this.keyword.toLowerCase()
      this.searchResults = this.presenters.filter((p) => {
        let isMatching = false
        if (p.name) {
          isMatching = p.name.toLowerCase().includes(keyword)
        }
        if (!isMatching && this.programs[p.id]) {
          isMatching = this.programs[p.id].toLowerCase().includes(keyword)
        }
        return isMatching
      })
    },
    async setProgramsForPresenter (presenter) {

      /**
       * Get 6 newest programs of a presentor and save it as a string
       */
      const { data: { items: podItems = [] } = {} } = await this.$api.persons.getAllNested('podcasts', presenter.id, {
        domain: 'generieke-content-eo',
        personType: 'presenter',
        limit: 6,
        offset: 0,
        aggregate: 0,
        press: 1,
      })
      const podcasts = podItems?.map(p => p.title)

      /**
       * Get 6 newest programs of a presentor and save it as a string
       */
      const { data: { items: progItems = [] } = {} } = await this.$api.persons.getAllNested('programs', presenter.id, {
        domain: 'generieke-content-eo',
        personType: 'presenter',
        limit: 6,
        offset: 0,
        aggregate: 0,
        press: 1,
      })
      const programs = progItems?.map(p => p.title)
      const listPodcasts = podcasts && podcasts.length ? `, ${podcasts.join(', ')}` : ""
      const list = `${programs.join(', ')}${listPodcasts}`

      this.$set(this.programs, presenter.id, list)
    },
  },
}
